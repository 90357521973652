import type { ComponentProps } from 'react';

import ImagePreloader from '@rio-cloud/rio-uikit/ImagePreloader';
import Spinner from '@rio-cloud/rio-uikit/Spinner';
import classNames from 'classnames';

type ImagePreloaderChildProps = {
    status: string;
    image: { src: string };
};

type ImageLoaderProps = {
    src: string;
    title: string;
    className?: string;
    imageProps?: ComponentProps<'img'>;
};

export const ImageLoader = (props: ImageLoaderProps) => {
    const { src, title, className = 'margin-bottom-25', imageProps } = props;

    const classes = classNames('border-style-solid border-width-1 border-color-light', className);

    return (
        <ImagePreloader src={src}>
            {({ status, image }: ImagePreloaderChildProps) => {
                if (status === ImagePreloader.STATUS_LOADED) {
                    return <img {...imageProps} className={`img-responsive ${classes}`} src={image.src} alt={title} />;
                }
                return (
                    <div className={`aspect-ratio-resizer ${classes}`}>
                        <svg viewBox={'0 0 970 347'} />
                        <div>
                            <div className={'content-center height-100pct'}>
                                <Spinner isInverse={false} />
                            </div>
                        </div>
                    </div>
                );
            }}
        </ImagePreloader>
    );
};
