import { detectFeatureToggles, asString, asBoolean } from '@rio-cloud/rio-uikit/urlFeatureToggles';

import { useUserAccount } from '../login/loginSlice';

// Define old toggles to clean up
const oldToggles = ['ft_eventTeaser', 'ft_survey', 'ft_menu', 'ft_groupedPois'];

// Define toggle transformation functions with their respective names and
// export the detected feature toggles as a singleton
export const featureToggles = detectFeatureToggles(
    {
        enforcedEnv: asString('ft_env'),
        enforcedLocale: asString('ft_enforceLocale'),
        enforcedLocalWidget: asString('ft_localWidget'),
        tracing: asBoolean('ft_tracing'),
        support: asBoolean('ft_support'),
        demo: asBoolean('ft_demo'),
        simulateGeoBooked: asString('ft_simulateGeoBooked'),
        smartRoutePlanning: asBoolean('ft_smartRoutePlanning'),
        smartRoutePlanningBooking: asBoolean('ft_srpBooking'),
        deliveryStatus: asBoolean('ft_deliveryStatus'),
        simplePay: asBoolean('ft_simplePay'),
        simplePayTest: asBoolean('ft_simplePayTest'),
        devMode: asBoolean('ft_devMode'),
        skipChristmas: asBoolean('skipChristmas'),
    },
    oldToggles
);

export const useWhiteListedFeatureToggles = (whiteList: string[] = []) => {
    const accountId = useUserAccount();
    return whiteList.includes(accountId);
};

export const useSmartRouteFeatureToggle = () => {
    const smartRouteWhiteList = [
        'mockaccount',
        '8ef04c71-8105-4bfb-a97c-a70db9d66975', // "RIO VIT"
        '237c58e3-60b5-4442-a9ec-8641ea39325b', // "Lastkraftwagenbauer"
        '284b3c1f-72c1-4669-a51b-c7ca9cc3c967', // “Demofleet MAN Sales”
        'ac464fce-f700-49a7-aa13-60b0c5c3f9ce', // “Testaccount MAN Truck & Bus SE”
        '0eaa962f-ddf9-4280-9d37-3d0760959493', // "EEV-Trucktesting"
        'be0fa59d-6b42-4025-9039-1c1ac8efb4e1', // MAN Truck and Bus SE
        '0ff74f0d-c891-47ca-a7e6-9501be388fe7', // LoadFox
    ];
    return useWhiteListedFeatureToggles(smartRouteWhiteList);
};
