import { useState } from 'react';
import Button from '@rio-cloud/rio-uikit/Button';
import Dialog from '@rio-cloud/rio-uikit/Dialog';

import { TimedBottomSheet } from '../../components/TimedBottomSheet';
import { featureToggles } from '../../configuration/setup/featureToggles';
import { ImageLoader } from '../../components/ImageLoader';
import FeedbackImage from './csm_dialog_feedback.webp';
import { gaPush, TRACKING_CATEGORIES } from '../../configuration/setup/googleTagManager';
import { useAppSelector } from '../../configuration/setup/hooks';
import { getLocale } from '../../configuration/lang/langSlice';

/*
 * Dummy example for how to use the BottomSheet
 *
 * Note:
 * Don't forget to cleanup the localStorage for the user when removing a feature
 * by setting "cleanupLocalStorage" to "true" and deploy it like this.
 * The cleanup will remove the localStorage flag on mount and will not show the bottom sheet.
 */

const SHOW_AFTER_IN_MS = 60_000; // 1 minute
const HIDE_AFTER_IN_MS = 60_000; // 1 minute

export const MarketplaceSurveyBottomSheet = () => {
    const [isDismissed, setIsDismissed] = useState(false);
    const [showPromotionDialog, setShowPromotionDialog] = useState(false);
    const [acceptToGiveFeedback, setAcceptToGiveFeedback] = useState(false);

    const locale = useAppSelector(getLocale);
    if (locale !== 'de-DE') {
        return null;
    }

    const handleCloseBottomSheet = () => {
        gaPush({
            category: TRACKING_CATEGORIES.PROMOTION,
            label: 'MarketplaceSurvey BottomSheet closed',
            action: 'Closed MarketplaceSurvey BottomSheet via close button',
        });
    };

    const handleOpenDialog = () => {
        setShowPromotionDialog(true);
        gaPush({
            category: TRACKING_CATEGORIES.PROMOTION,
            label: 'MarketplaceSurvey BottomSheet button clicked',
            action: 'Read more button clicked in MarketplaceSurvey BottomSheet',
        });
    };

    const handleCloseDialog = () => {
        setShowPromotionDialog(false);
        setIsDismissed(true);
        gaPush({
            category: TRACKING_CATEGORIES.PROMOTION,
            label: 'MarketplaceSurvey Dialog closed',
            action: 'Closed MarketplaceSurvey Dialog via close button or esc',
        });
    };

    const handleAcceptGivingFeedback = () => {
        setAcceptToGiveFeedback(true);
        gaPush({
            category: TRACKING_CATEGORIES.PROMOTION,
            label: 'MarketplaceSurvey Dialog survey shown',
            action: 'User clicked on MarketplaceSurvey Dialog footer button to see the survey.',
        });
    };

    const handleCloseSurvey = () => {
        setShowPromotionDialog(false);
        setIsDismissed(true);
        gaPush({
            category: TRACKING_CATEGORIES.PROMOTION,
            label: 'MarketplaceSurvey Dialog survey closed',
            action: 'User closed the MarketplaceSurvey Dialog via footer button.',
        });
    };

    const bodyStyle = acceptToGiveFeedback
        ? {
              minHeight: '520px',
          }
        : {};

    return (
        <>
            <TimedBottomSheet
                dismissed={isDismissed}
                featureName='MarketplaceSurveyBottomSheet'
                showAfter={SHOW_AFTER_IN_MS}
                hideAfter={HIDE_AFTER_IN_MS}
                className='max-width-400'
                onClose={handleCloseBottomSheet}
                cleanupLocalStorage={true}
            >
                <div className='display-flex flex-column align-items-center gap-20'>
                    <div className='display-flex flex-column'>
                        <div className='text-size-16 text-medium margin-bottom-10'>Ihr Feedback wird belohnt</div>
                        <div>
                            Geben Sie uns Feedback zu unserem <strong>Marketplace</strong> und erhalten Sie dafür als
                            Belohnung ein RIO Überraschungspaket. Neugierig geworden?
                        </div>
                    </div>
                    <div>
                        <Button className='min-width-150' bsStyle={Button.PRIMARY} onClick={handleOpenDialog}>
                            Mehr erfahren...
                        </Button>
                    </div>
                </div>
            </TimedBottomSheet>

            <Dialog
                bsSize='sm'
                show={showPromotionDialog}
                title={<div>Ihr Feedback wird belohnt</div>}
                body={
                    <div style={bodyStyle}>
                        {acceptToGiveFeedback && (
                            <iframe
                                className='unstyled full-window'
                                title='Feedback Formular'
                                src='https://rio.cloud/de/landingpage/marketplace-category-feedback'
                            />
                        )}
                        {!acceptToGiveFeedback && (
                            <div className='section-content-container-body text-size-14'>
                                <p className='margin-top-0'>
                                    <strong>
                                        Fahrzeugortung und Wirtschaftliches Fahren?
                                        <br /> Aufteilung in RIO und MAN Produkte?
                                    </strong>
                                </p>
                                <p>Sie sind der Meinung unser Marketplace könnte übersichtlicher sein?</p>
                                <p>
                                    <strong>Geben Sie uns Feedback</strong> und erhalten Sie dafür als{' '}
                                    <strong>Belohnung</strong> ein <strong>RIO Überraschungspaket.</strong>
                                </p>
                                <p>
                                    <strong>Thema: </strong>Übersichtlichkeit und Kategorien
                                    <br />
                                    <strong>Dauer:</strong> ca. 30 Minuten
                                    <br />
                                    <strong>Ort:</strong> Online via Teams Meeting (ohne Login)
                                </p>
                                <ImageLoader className='margin-0' src={FeedbackImage} title='Feedback image' />
                            </div>
                        )}
                    </div>
                }
                footer={
                    <>
                        {acceptToGiveFeedback && (
                            <div className='display-flex justify-content-end btn-toolbar'>
                                <Button onClick={handleCloseSurvey}>Schließen</Button>
                            </div>
                        )}
                        {!acceptToGiveFeedback && (
                            <div className='display-flex justify-content-end btn-toolbar'>
                                <Button bsStyle='primary' onClick={handleAcceptGivingFeedback}>
                                    Ja, ich möchte Feedback geben
                                </Button>
                            </div>
                        )}
                    </>
                }
                onHide={handleCloseDialog}
                onEsc={handleCloseDialog}
            />
        </>
    );
};
