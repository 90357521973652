import React from 'react';
import UikitSpinner from '@rio-cloud/rio-uikit/Spinner';

const Spinner = UikitSpinner.default ?? UikitSpinner;

export const MainLoadingIndicator = () => (
    <div className={'height-100vh display-flex justify-content-center align-items-center'}>
        <Spinner isDoubleSized />
    </div>
);
