import React from 'react';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { Outlet } from 'react-router-dom';
import { SessionExpiredDialog } from '@rio-cloud/rio-session-expired-info';

import { ErrorBoundary } from '../components/ErrorBoundary';
import { useAccessToken } from '../configuration/tokenHandling/tokenSlice';
import { useDisplayMessages, useLocale } from '../configuration/lang/langSlice';
import {
    hideSessionExpiredDialog,
    useIsSessionExpired,
    useSessionExpiredAcknowledged,
} from '../configuration/login/loginSlice';
import { DEFAULT_LOCALE } from '../configuration/lang/lang';
import { useAppDispatch } from '../configuration/setup/hooks';
import { store } from '../configuration/setup/store';
import DefaultRedirect from '../routes/DefaultRedirect';
import { ConfigCatProvider, DataGovernance, createConsoleLogger, LogLevel } from 'configcat-react';

const App = () => {
    const dispatch = useAppDispatch();

    const accessToken = useAccessToken();
    const displayMessages = useDisplayMessages();
    const userLocale = useLocale();
    const isSessionExpired = useIsSessionExpired();
    const sessionExpiredAcknowledged = useSessionExpiredAcknowledged();

    if (!(displayMessages && userLocale)) {
        return null;
    }

    const handleSessionExpiredDialogClose = () => dispatch(hideSessionExpiredDialog());
    const showSessionExpired = isSessionExpired && !sessionExpiredAcknowledged;

    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={displayMessages}>
            <ConfigCatProvider
                sdkKey='configcat-sdk-1/iLfcCMAtjEyICYJDHb6fhg/9l4CgqIJUEKxDWb2HbJdqQ'
                options={{ logger: createConsoleLogger(LogLevel.Info), dataGovernance: DataGovernance.EuOnly }}
            >
                <>
                    <SessionExpiredDialog
                        locale={userLocale}
                        onClose={handleSessionExpiredDialogClose}
                        show={showSessionExpired}
                    />
                    <Outlet />
                    <input data-semantic-id='access-token' type='hidden' value={accessToken || ''} />
                </>
            </ConfigCatProvider>
        </IntlProvider>
    );
};

export const AppContainer = () => (
    <ErrorBoundary>
        <DefaultRedirect />
        <Provider store={store}>
            <App />
        </Provider>
    </ErrorBoundary>
);
