import { useEffect, useRef, useState } from 'react';
import Button from '@rio-cloud/rio-uikit/Button';
import useHover from '@rio-cloud/rio-uikit/useHover';
import TimedBottomSheet from '@rio-cloud/rio-uikit/TimedBottomSheet';
import { motion } from '@rio-cloud/rio-uikit/framer-motion';
import classNames from 'classnames';
import IframeResizer from 'iframe-resizer-react';

import { gaPush, trackingAttributes, TRACKING_CATEGORIES } from '../../configuration/setup/googleTagManager';

import { DEMO_FLEET_ACCOUNT_ID } from '../../data/dataDefinition';
import { FormattedMessage } from 'react-intl';
import { ImageLoader } from '../../components/ImageLoader';
import PocketDriverImage from '../../assets/bottomSheets/pocket-driver.webp';
import { useLocale } from '../../configuration/lang/langSlice';
import { useUserAccount, useUserProfile } from '../../configuration/login/loginSlice';
import { TENANT_RIO_EU_PROD, TENANT_RIO_EU_TEST, useTenant } from '../../configuration/tokenHandling/tokenSlice';

const useElapsedTime = () => {
    const startTimeRef = useRef(Date.now());

    const getElapsedTime = () => {
        return Date.now() - startTimeRef.current;
    };

    useEffect(() => {
        startTimeRef.current = Date.now();
    }, []);

    return getElapsedTime;
};

/*
 * Order Communication Survey BottomSheet.
 *
 * Note:
 * Don't forget to cleanup the localStorage for the user when removing a feature
 * by setting "cleanupLocalStorage" to "true" and deploy it like this.
 * The cleanup will remove the localStorage flag on mount and will not show the bottom sheet.
 */

const IS_DISABLED = true;

const SHOW_AFTER_IN_MS = 30_000; // 30 seconds
const HIDE_AFTER_IN_MS = 120_000; // 2 minutes
const HIDE_AFTER_SUBMIT_IN_MS = 2_000; // 2 seconds

const disallowedAccounts = [DEMO_FLEET_ACCOUNT_ID];

export const OrderCommunicationSurveyBottomSheet = () => {
    const [isDismissed, setIsDismissed] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [isFinished, setIsFinished] = useState(false);
    const [isPostponed, setIsPostponed] = useState(false);

    const getElapsedTime = useElapsedTime();

    // Don't close itself if the user types in into the textfield or
    // interacts with the bottom sheet
    const [isDirty, setIsDirty] = useState(false);

    const locale = useLocale();
    const userProfile = useUserProfile();
    const userAccount = useUserAccount();
    const tenant = useTenant();

    const isAllowedForTenants = [TENANT_RIO_EU_PROD, TENANT_RIO_EU_TEST].includes(tenant);

    const url = [
        'https://cloud.news.rio.cloud/lead_generation',
        '?topic=Auftragskommunikation',
        `&locale=${locale}`,
        `&email=${encodeURIComponent(userProfile.email)}`,
        '&hideName=true',
        '&hideEmail=true',
        '&hidePhone=true',
        '&hideNewsletter=true',
        `&title=${encodeURIComponent(
            'Lernen Sie den aktuellen Entwicklungsstand der RIO Auftragskommunikation kennen'
        )}`,
        `&intro=${encodeURIComponent(
            'In einem einstündigen Gespräch führen wir Sie durch unsere Produktdemo und nehmen Ihr Feedback auf. Dieses fließt direkt in die Weiterentwicklung ein, um unser Produkt optimal an Ihre Bedürfnisse anzupassen.'
        )}`,
    ].join('');

    if (disallowedAccounts.includes(userAccount) || !isAllowedForTenants) {
        return null;
    }

    const handleCloseBottomSheet = () => {
        setIsDismissed(true);

        gaPush({
            category: TRACKING_CATEGORIES.PROMOTION,
            label: 'Order Communication survey bottom sheet closed',
            action: `Order Communication survey bottom sheet via close button from step ${currentStep}`,
        });

        const elapsedTimeInMilliSeconds = getElapsedTime() - SHOW_AFTER_IN_MS;
        const elapsedTimeInSeconds = elapsedTimeInMilliSeconds / 1000;

        let logTime = 'after more than 10 seconds';

        if (elapsedTimeInSeconds <= 3) {
            logTime = 'in less than 3 seconds';
        } else if (elapsedTimeInSeconds < 5) {
            logTime = 'in less than 5 seconds';
        } else if (elapsedTimeInSeconds < 10) {
            logTime = 'in less than 10 seconds';
        }

        gaPush({
            category: TRACKING_CATEGORIES.PROMOTION,
            label: 'Order Communication survey bottom sheet closed',
            action: `Order Communication survey bottom sheet via close button from step ${currentStep} ${logTime}`,
        });
    };

    const handlePostpone = () => {
        setIsPostponed(true);
        gaPush({
            category: TRACKING_CATEGORIES.PROMOTION,
            label: 'Order Communication survey bottom sheet postpone',
            action: 'Order Communication survey bottom sheet postponed',
        });
    };

    const handleInterested = () => {
        gaPush({
            category: TRACKING_CATEGORIES.PROMOTION,
            label: 'Order Communication survey bottom sheet interested',
            action: 'Order Communication survey bottom sheet interested click',
        });

        setIsDirty(true);

        // show second step
        setCurrentStep(2);
    };

    const handleNotInterested = () => {
        gaPush({
            category: TRACKING_CATEGORIES.PROMOTION,
            label: 'Order Communication survey bottom sheet not interested',
            action: 'Order Communication survey bottom sheet not interested click',
        });

        // show thank you message and close bottom sheet after 5 sec
        setCurrentStep(3);
        setIsFinished(true);

        // After showing the thank you message, set the bottom sheet to dismissed, so it won't be
        // shown again on next page load
        setTimeout(() => setIsDismissed(true), HIDE_AFTER_SUBMIT_IN_MS);
    };

    let hideTimeout: number | undefined = isFinished ? HIDE_AFTER_SUBMIT_IN_MS : HIDE_AFTER_IN_MS;
    if (isDirty) {
        hideTimeout = undefined;
    }

    if (isPostponed) {
        return null;
    }

    return (
        <TimedBottomSheet
            featureName='OrderCommunicationSurveyBottomSheet'
            dismissed={isDismissed}
            showAfter={SHOW_AFTER_IN_MS}
            hideAfter={hideTimeout}
            onClose={handleCloseBottomSheet}
            bodyClassName='padding-25'
            className='width-600 position-relative overflow-hidden'
            cleanupLocalStorage={IS_DISABLED}
            {...trackingAttributes(TRACKING_CATEGORIES.PROMOTION, 'Order Communication survey bottom sheet')}
        >
            {currentStep === 1 && (
                <>
                    {/* <div className='width-100 height-5 position-absolute top-25 left-25 display-grid grid-cols-2 gap-5'>
                        <div className='rounded bg-dark' />
                        <div className='rounded bg-lighter' />
                    </div> */}
                    <div className='display-flex gap-15'>
                        <div className='position-absolute top-10' style={{ right: '45px' }}>
                            <Button bsStyle='muted' bsSize='sm' onClick={handlePostpone}>
                                <FormattedMessage id='intl-msg:livemonitor.label.askMeLater' />
                            </Button>
                        </div>
                        <div className='flex-1-1 display-flex flex-column justify-content-between'>
                            <div>
                                <div className='text-medium text-size-16 margin-bottom-15'>
                                    <FormattedMessage id='intl-msg:livemonitor.feedback.order.headline' />
                                </div>
                                <ul className='icon-list margin-bottom-0'>
                                    <FormattedMessage
                                        id='intl-msg:livemonitor.feedback.order.description'
                                        values={{
                                            li: chunk => (
                                                <li className='margin-bottom-5'>
                                                    <span className='rioglyph rioglyph-ok-sign text-color-success' />
                                                    <span>{chunk}</span>
                                                </li>
                                            ),
                                        }}
                                    />
                                </ul>
                            </div>
                            <div className='display-grid grid-cols-2 gap-15 padding-top-5 margin-bottom-15'>
                                <FeedbackItem
                                    iconName='thumbs-down'
                                    text={<FormattedMessage id='intl-msg:livemonitor.label.iamNotInterested' />}
                                    onClick={handleNotInterested}
                                />
                                <FeedbackItem
                                    iconName='thumbs-up'
                                    text={<FormattedMessage id='intl-msg:livemonitor.label.iamInterested' />}
                                    onClick={handleInterested}
                                />
                            </div>
                        </div>
                        <div className='padding-left-15'>
                            <ImageLoader
                                className='margin-0 border-none'
                                src={PocketDriverImage}
                                title='Pocket Driver App'
                                imageProps={{
                                    style: {
                                        rotate: '2deg',
                                        transform: 'translate(0%, 12%)',
                                        maxWidth: '300px',
                                        filter: 'drop-shadow(2px 4px 6px rgba(0, 0, 0, .5))',
                                    },
                                }}
                            />
                        </div>
                    </div>
                </>
            )}
            {currentStep === 2 && (
                <motion.div initial={{ opacity: 0, x: 20 }} animate={{ opacity: 1, x: 0 }} className=''>
                    <div>
                        {/* <div className='width-100 height-5 position-absolute top-25 left-25 display-grid grid-cols-2 gap-5'>
                            <div className='rounded bg-lighter' />
                            <div className='rounded bg-dark' />
                        </div> */}
                        <div className='margin-top-25 min-height-250'>
                            <IframeResizer
                                src={url}
                                checkOrigin={false}
                                style={{ minWidth: '100%', border: '0' }}
                                title='finalize step'
                            />
                        </div>
                    </div>
                </motion.div>
            )}
            {isFinished && (
                <motion.div
                    initial={{ opacity: 0, x: 20 }}
                    animate={{ opacity: 1, x: 0 }}
                    className='display-flex gap-10 align-items-center'
                >
                    <div className='text-color-success text-size-20'>
                        <span className='rioglyph rioglyph-ok-sign' />
                    </div>
                    <div className='text-medium text-color-darker text-size-16'>
                        <FormattedMessage id='intl-msg:livemonitor.feedback.map.thanks' />
                    </div>
                </motion.div>
            )}
        </TimedBottomSheet>
    );
};

const FeedbackItem = ({
    text,
    iconName,
    onClick,
}: {
    text: React.ReactNode;
    iconName: string;
    onClick: VoidFunction;
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const isHover = useHover(ref);

    const iconClasses = classNames(
        'width-50',
        'aspect-ratio-1',
        'border rounded-circle',
        'bg-lightest',
        isHover ? 'border-color-primary text-color-primary' : 'border-color-lightest',
        'display-grid place-items-center',
        'text-size-14'
    );

    const isUp = iconName.includes('up');

    return (
        <div
            ref={ref}
            className='display-flex flex-column gap-10 align-items-center text-center cursor-pointer'
            onClick={onClick}
        >
            <div className={iconClasses}>
                <motion.span
                    initial={{ y: 0, rotate: 0 }}
                    animate={isHover ? { y: [0, isUp ? -3 : 3, 0], rotate: [0, isUp ? -10 : 10, 0] } : { y: 0 }}
                    transition={{
                        duration: 0.6,
                        repeat: isHover ? Number.POSITIVE_INFINITY : 0,
                        repeatType: 'loop',
                    }}
                    className={`rioglyph rioglyph-${iconName} text-size-200pct`}
                />
            </div>
            <div
                className={`line-height-125rel text-wrap-balance text-medium ${
                    isHover ? 'text-color-primary' : 'text-color-darker'
                }`}
            >
                {text}
            </div>
        </div>
    );
};
